import React, { useState, useEffect } from 'react';
import './style.css';
import Header from './header';
import UsernameInput from './usernameinput';
import GetOffers from './GetOffers';

const App = () => {
  const fetchedOffers = GetOffers();
  const [username, setUsername] = useState('');
  const [isMainAppVisible, setIsMainAppVisible] = useState(false);

  const Footer = () => {
    return (
      <div className="task-footer">
        <b>Waiting For Task Completion...</b>
        <br />
        <div className="loader loader-striped active">
          <div
            role="loaderbar loader-striped"
            style={{ width: '100%' }}
            className="loader-bar"
          ></div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (username) {
      setIsMainAppVisible(true);
    }
  }, [username]);

  const OfferComponent = ({ offer }) => {
    const [animatedPayout, setAnimatedPayout] = useState(0);

    useEffect(() => {
      const payoutInCents = Math.round(offer.payout * 100) * 5;
      const duration = 1000; // Duration of animation in milliseconds
      let start = null;

      const animate = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const current = Math.min(
          payoutInCents,
          Math.round((payoutInCents * progress) / duration)
        );
        setAnimatedPayout(current);
        if (progress < duration) {
          window.requestAnimationFrame(animate);
        }
      };

      window.requestAnimationFrame(animate);
    }, [offer]);

    const payoutInCents = Math.round(offer.payout * 100) * 5;
    if (payoutInCents < 125) {
      return null;
    }

    let updatedAdcopy = offer.adcopy
      .replace(
        /unlock your content!/g,
        `instantly add ${payoutInCents} dice to ${username}'s account!`
      )
      .replace(
        /unlock this content/g,
        `instantly add ${payoutInCents} dice to ${username}'s account!`
      )
      .replace(
        /unlock your offer/g,
        `instantly add ${payoutInCents} dice to ${username}'s account!`
      )
      .replace(
        /unlock this offer/g,
        `instantly add ${payoutInCents} dice to ${username}'s account!`
      );

    return (
      <div key={offer.offerid} className="offer">
        <img src={offer.picture} alt={offer.name} />
        <div className="offer-details">
          <p className="offer-name">{offer.name_short}</p>
          <p className="offer-description">{updatedAdcopy}</p>
        </div>
        <button
          className="coin-button"
          onClick={() => (window.location.href = offer.link)}
        >
          <i className="fa fa-dice"></i> {animatedPayout}
        </button>
      </div>
    );
  };

  const renderContent = () => {
    return fetchedOffers
      .map((offer) => <OfferComponent key={offer.offerid} offer={offer} />)
      .filter((offerComponent) => offerComponent !== null); // Filter out null values
  };

  if (!username) {
    return <UsernameInput setUsername={setUsername} />;
  }

  return (
    <div>
      <Header />
      <div
        className={`app ${isMainAppVisible ? 'pop-in-animation' : 'hidden'}`}
      >
        <div className="offer-wall">{renderContent()}</div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
