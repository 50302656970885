import React, { useState, useEffect } from 'react';
import Header from './header';
import avatarImage from './img/avatar.png';
import { Helmet } from 'react-helmet';

const UsernameInput = ({ setUsername }) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState('');
  const [progressWidth, setProgressWidth] = useState(0);
  const [inputError, setInputError] = useState(false);

  const handleSubmit = () => {
    if (inputValue.trim() === '') {
      setInputError(true);
      return;
    }

    setIsLoading(true);
    setLoadingStep('Verifying Username');
    setProgressWidth(20);

    setTimeout(() => {
      setLoadingStep('Verified!');
      setProgressWidth(33);

      setTimeout(() => {
        setLoadingStep(`Connecting to Account ${inputValue}`);
        setProgressWidth(66);

        setTimeout(() => {
          setLoadingStep(`Connected to ${inputValue}!`);
          setProgressWidth(80);

          setTimeout(() => {
            setLoadingStep('Loading Dice Generator');
            setProgressWidth(100);

            setTimeout(() => {
              setIsLoading(false);
              setUsername(inputValue);
            }, 1200); // 1 second for 'Loading Dice Generator'
          }, 900); // 1 second for 'Connected to ${inputValue}!'
        }, randomTime(3000, 4000)); // Random time between 3-5 seconds for 'Connecting to Account ${inputValue}'
      }, 300); // 0.3 seconds for 'Verified!'
    }, 1500); // 1 second for 'Verifying Username'
  };

  const randomTime = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (inputError) {
      setInputError(false);
    }
  };

  return (
    <div>
      <Helmet>
        ‍
        <title>
          GiveDice - Free Unlimited Dice Generator for Yahtzee With Buddies!
        </title>
        <meta
          name="description"
          content="Get unlimited free dice for the Yahtzee with Buddies app. Use our Free Dice Cheat app to enhance your gameplay with an endless dice supply. Easy, free, and fast."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta
          name="twitter:title"
          content="Yahtzee With Buddies! - Free Dice Generator"
        />
        <meta
          name="twitter:description"
          content="Unlimited dice cheat for Yahtzee with Buddies!"
        />
        <meta name="twitter:image" content="url_to_image" />
        <meta
          property="og:title"
          content="Free Dice Generator for Yahtzee With Buddies - Unlimited Free Dice"
        />
        <meta
          property="og:description"
          content="Get unlimited free dice for the Yahtzee with Buddies app. Use our Free Dice Cheat app to enhance your gameplay with an endless dice supply. Easy, free, and fast."
        />
        ‍
        <meta
          name="keywords"
          content="Yahtzee with Buddies Dice Generator, Yahtzee with Buddies Dice Cheat, Yahtzee with Buddies Dice Hack, Yahtzee with Buddies Dice APK Hacked, Free Dice Generator, Unlimited Yahtzee Dice"
        />
      </Helmet>
      <Header />
      <div className="bottom-container">
        <div className="loading-image">
          <img src={avatarImage} alt="Loading" />
        </div>
        {!isLoading ? (
          <div className="input-section">
            <div className="login-title">
              Yahtzee! Buddies - Dice Helper v9.08
            </div>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              placeholder="Username..."
              className={`input-with-icon ${inputError ? 'input-error' : ''}`}
            />
            <button onClick={handleSubmit}>Connect</button>
          </div>
        ) : (
          <div className="loading-section">
            <div className="loading-text">
              <div>{loadingStep}</div>
            </div>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `${progressWidth}%` }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsernameInput;
