import React, { useState } from 'react';
import Sparkles from './sparkles';
import './style.css';
import { Helmet } from 'react-helmet';
import diceImage from './img/dice.png';
import trophyImage from './img/trophey.png';
import newsImage from './img/news.png';
import plusImage from './img/plus.png';

const Header = () => {
  const [count, setCount] = useState(6338); // Initialize state

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1); // Function to increment count
  };

  return (
    <header className="app-header">
      <Helmet>
        ‍
        <title>
          GiveDice - Free Unlimited Dice Generator for Yahtzee With Buddies!
        </title>
        <meta
          name="description"
          content="Get unlimited free dice for the Yahtzee with Buddies app. Use our Free Dice Cheat app to enhance your gameplay with an endless dice supply. Easy, free, and fast."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta
          name="twitter:title"
          content="Yahtzee With Buddies! - Free Dice Generator"
        />
        <meta
          name="twitter:description"
          content="Unlimited dice cheat for Yahtzee with Buddies!"
        />
        <meta name="twitter:image" content="url_to_image" />
        <meta
          property="og:title"
          content="Free Dice Generator for Yahtzee With Buddies - Unlimited Free Dice"
        />
        <meta
          property="og:description"
          content="Get unlimited free dice for the Yahtzee with Buddies app. Use our Free Dice Cheat app to enhance your gameplay with an endless dice supply. Easy, free, and fast."
        />
        ‍
        <meta
          name="keywords"
          content="Yahtzee with Buddies Dice Generator, Yahtzee with Buddies Dice Cheat, Yahtzee with Buddies Dice Hack, Yahtzee with Buddies Dice APK Hacked, Free Dice Generator, Unlimited Yahtzee Dice"
        />
      </Helmet>
      <div className="stats-header">
        <div className="avatar-container">
          <div className="header-center">GiveDice</div>
        </div>
        <div className="dice-container">
          <div className="dice-content">
            <img src={diceImage} alt="Dice" className="dice-icon" />
            <div className="dice-count">{count}</div>
            <img
              src={plusImage}
              alt="Add"
              className="add-button"
              onClick={handleIncrement}
            />
          </div>
        </div>
        <div className="trophy-container">
          <img src={trophyImage} alt="Trophy" className="trophy-icon" />
        </div>
        <div className="news-container">
          <Sparkles classname="spark"></Sparkles>{' '}
          <img src={newsImage} alt="News" className="news-icon" />
        </div>
      </div>
    </header>
  );
};

export default Header;
