import { useState, useEffect } from 'react';

const useGetOffers = () => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    // Fetch IP and then Fetch Offers
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        sendIpToServer(data.ip);
      })
      .catch((error) => console.error('Error fetching IP:', error));
  }, []);

  const sendIpToServer = (ipAddress) => {
    fetch('https://givedice.com/offers.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ip: ipAddress }),
    })
      .then((response) => response.json())
      .then((data) => {
        setOffers(data.offers); // Set the offers in state
      })
      .catch((error) => console.error('Error sending IP:', error));
  };

  return offers;
};

export default useGetOffers;
